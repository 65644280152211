import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const BorderedContent = ({
  narcolepsy,
  ih,
  extraLargeCurve,
  children,
  thinBorder,
  extraCurved,
  bgColour,
  addedStyles,
  addedContainerStyles,
}) => {
  return (
    <div
      css={[
        tw`relative cost-and-coverage-border-gradient block z-10 col-span-full w-full h-full rounded-[45px] px-[25px] before:(content[''] absolute left-0 right-0 top-0 bottom-0 m-[5px] bg-white rounded-[41px])`,
        bgColour && bgColour === "lilac" && tw`before:(bg-lilac)`,
        narcolepsy && tw`gradient1`,
        ih && tw`gradientHcpIH`,
        thinBorder && tw`before:m-[3px]`,
        extraCurved && tw`rounded-[60px] before:(rounded-[56px])`,
        extraLargeCurve && tw`rounded-[112px] before:(rounded-[108px])`,
        addedContainerStyles && addedContainerStyles,
      ]}
    >
      <div css={[tw`relative z-20`, addedStyles && addedStyles]}>
        {children}
      </div>
    </div>
  )
}

export default BorderedContent

BorderedContent.propTypes = {
  narcolepsy: PropTypes.bool,
  ih: PropTypes.bool,
  thinBorder: PropTypes.bool,
  addedStyles: PropTypes.object,
  extraCurved: PropTypes.bool,
  extraLargeCurve: PropTypes.bool,
}

BorderedContent.defaultProps = {
  narcolepsy: false,
  ih: false,
  thinBorder: false,
  addedStyles: null,
  extraCurved: null,
  extraLargeCurve: null,
}
