import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import ButtonIH from "./buttonIH"
import Anchor from "./anchor"
import { purpleButton } from "@styles/ui"

const Crosslinks = ({ ctas, narc }) => {
  //cta in this format
  // const crosslinksCtas = {
  //   heading: "Get helpful resources for your adult patients with IH",
  //   desktopWidth: tw`xl:w-[500px]`,
  //   links: [
  //     {
  //       text: 'PATIENT FINANCIAL ASSISTANCE & SUPPORT',
  //       url: "/jazzcares-for-xywav/#financial assistance",
  //     },
  //     {
  //       text: 'PATIENT SUPPORT WITH MYWAV.COM',
  //       url: "/jazzcares-for-xywav/#mywav",
  //     },
  //   ]
  // }

  return (
    <div tw="m-auto w-full max-w-[732px] xl:(max-w-[1170px])">
      <h2
        css={[
          tw`font-montserrat font-semibold text-center mb-5 leading-[1.15]`,
          tw`text-[20px] md:(text-4xl mb-8)`,
        ]}
      >
        {ctas.heading}
      </h2>

      <div
        css={[
          tw`flex flex-col justify-center items-center xl:(flex-row items-start gap-x-[30px])`,
        ]}
      >
        {ctas.links.map((link, i) => {
          console.log(link)
          return narc ? (
            <Anchor
              key={i}
              isExternal={link.isExternal}
              newTab={link.newTab}
              addedStyles={[
                purpleButton,
                tw`w-full mx-auto mb-[30px] py-[10px] px-11 md:(min-w-[500px] py-[15px]) xl:(max-w-[unset] mx-[unset] min-w-[435px] px-10 mb-0)`,
              ]}
              link={link.url}
            >
              {link.text}
            </Anchor>
          ) : (
            <ButtonIH
              key={i}
              isExternal={link.isExternal}
              newTab={link.newTab}
              link={link.url}
              stackedSpacing={i > 0 ? true : false}
              width={ctas && ctas.desktopWidth}
            >
              {link.text}
            </ButtonIH>
          )
        })}
      </div>
    </div>
  )
}

export default Crosslinks

Crosslinks.propTypes = {
  ctas: PropTypes.object.isRequired,
  narc: PropTypes.bool,
}
